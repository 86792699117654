import Background from './Background.js';
import Content from './Content.js';

const BigPage = () => {
    return (
        <>
            <Background />
            <Content />
        </>
    );
};

export default BigPage;
