import React from 'react';
import Text from './Text.js';
import ScrollDirections from './ScrollDirections.js';

const Header = () => (
    <div
        style={{
            marginTop: '7%',
        }}
    >
        <Text />
        <ScrollDirections />
    </div>
);

export default Header;
