import Background from './Background';
import Description from './Description';
import Title from './Title.js';
import VideoPlayer from './VideoPlayer';

const MeteorFingers = () => {
    return (
        <div>
            <Title />
            <VideoPlayer />
            <Description />
            <Background />
        </div>
    );
};

export default MeteorFingers;
