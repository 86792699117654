import Background from './Background';
import Description from './Description';
import EmbeddedPlay from './EmbeddedPlay';
import Title from './Title';

const FishyFeederBigScreen = () => {
    return (
        <>
            <Title />
            <Background />
            <Description />
            <EmbeddedPlay />
        </>
    );
};

export default FishyFeederBigScreen;
